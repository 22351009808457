<template>
  <v-layout column justify-center fill-height class="categories-mission">
    <ResizableText
      :message="mission.instructions"
      class="categories-mission__title"
    />

    <OpacityTransition>
      <OptionsList v-if="!isExplain" :options="options" show autoWidth>
        <template #option="{ option }">
          <FlipVerticalTransition>
            <v-tooltip bottom v-if="option.played">
              <template v-slot:activator="{ on }">
                <ChoiceBtn
                  v-on="on"
                  :size="!enlargedUI && 'sm'"
                  :style="
                    !enlargedUI &&
                    option.display.length > 13 &&
                    'font-size: 75%'
                  "
                >
                  {{ option.display }}
                </ChoiceBtn>
              </template>
              {{ option.display }}
            </v-tooltip>

            <ChoiceBtn
              v-else
              :size="!enlargedUI && 'sm'"
              :secondary="true"
              @click="submit(option.display, true)"
            >
              {{ isHost ? option.display : "?" }}
            </ChoiceBtn>
          </FlipVerticalTransition>
        </template>
      </OptionsList>
    </OpacityTransition>

    <SlideDownTransition maxHeight="160px">
      <v-flex shrink v-if="isHost && isPlayTime" style="margin-bottom: 4px">
        <v-btn
          @click="toggleReveal()"
          class="categories-mission__toggle-visibility-btn"
        >
          <v-icon class="categories-mission__toggle-visibility-btn__icon">
            visibility
          </v-icon>
          <span>
            {{ revealCategoryAnswers ? "Hide Answers" : "Reveal Answers" }}
          </span>
        </v-btn>
      </v-flex>
    </SlideDownTransition>

    <SlideDownTransition maxHeight="160px">
      <v-flex shrink class="w-full" v-if="isInputAvailable">
        <TextField
          class="mission-text-feild"
          allowFreeSubmit
          autoFocusOnSubmit
          :placeholder="placeholder"
          :status="status"
          :show="'show'"
          :buttonText="'SUBMIT'"
          @onSubmit="submit"
        />
      </v-flex>
    </SlideDownTransition>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { toRefs } from "@vue/composition-api"
import { GameMixin } from "@/mixins"
import { RtbButton } from "@/components/ui"
import { Mode } from "@shared/enums"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import OpacityTransition from "@/components/GroupTeams/Common/GameModes/OpacityTransition.vue"
import SlideDownTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideDownTransition"
import FlipVerticalTransition from "@/components/GroupTeams/Common/Games/GameCardParts/FlipVerticalTransition"
import ChoiceBtn from "@/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn"
import OptionsList from "@/components/GroupTeams/Common/Games/GameCardParts/OptionsList"

import User from "@shared/User"
import { serialize } from "@/helpers"

import TextField from "../TextField.vue"
import useCategories from "./useCategories"

export default {
  name: "Categories",
  mixins: [GameMixin],
  components: {
    OptionsList,
    ChoiceBtn,
    FlipVerticalTransition,
    SlideDownTransition,
    OpacityTransition,
    ResizableText,
    TextField,
    RtbButton
  },
  props: {
    mode: String,
    mission: Object
  },
  setup(props) {
    const { mission } = toRefs(props)
    const { answers } = useCategories(mission)
    return {
      answers
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "isAudit", "user"]),
    ...mapGetters(["missionPlaysArray", "gameID", "orgID"]),
    ...mapGetters("GameUsers", ["users"]),
    isViewerPresenter() {
      return this.$store.getters["group/isViewerPresenter"]
    },
    enlargedUI() {
      return (
        this.$store.getters.gameStatus?.enlargedUI && this.isViewerPresenter
      )
    },
    isInputAvailable() {
      if (this.isViewerPresenter) return false

      return !this.isHost && !this.isExplain
    },
    plays() {
      const teamID = this.teamID
      return this.missionPlaysArray.filter(object => object.teamID === teamID)
    },
    given() {
      return this.plays.reduce((acc, val) => {
        if (typeof val?.answer === "string") {
          acc.push(val.answer)
        } else if (Array.isArray(val?.answer)) {
          val.answer.forEach(answer => {
            if (typeof answer === "string") acc.push(answer)
          })
        }
        return acc
      }, [])
    },
    normalized() {
      return this.given.map(serialize)
    },
    normalizedWithOptions() {
      const options = this.optionsWithDisplay
      return this.normalized.reduce((acc, val) => {
        const found = options.find(({ options }) => options.includes(val))
        return found ? [...acc, ...found.options] : [...acc, val]
      }, [])
    },
    revealCategoryAnswers() {
      return Boolean(this.$store.getters.gameStatus?.revealCategoryAnswers)
    },
    options() {
      if (this.revealCategoryAnswers) {
        return this.optionsWithDisplay.map(option => ({
          display: option.display,
          played: true
        }))
      }

      const given = this.normalizedWithOptions

      return this.optionsWithDisplay.map(option => ({
        display: option.display,
        played: option.options.some(string => given.includes(string))
      }))
    },
    teamID() {
      return this.$store.getters["group/canViewerSelectGlobalTeam"] &&
        !User.isPresenter(this.user)
        ? this.$store.getters["group/globalTeamID"]
        : this.$store.getters.teamID
    },
    optionsWithDisplay() {
      return this.answers.map(string => {
        const options = String(string).split(",")
        return {
          display: String(options[0]).trim(),
          options: options.map(serialize)
        }
      })
    },
    optionsText() {
      return this.optionsWithDisplay.map(({ display }) => display).join(", ")
    },
    status() {
      return !this.isExplain ? "active" : ""
    },
    optionsClasses() {
      return {
        show: this.mode === Mode.Play
      }
    },
    isExplain() {
      return this.mode === Mode.Explain
    },
    isPlayTime() {
      return (
        this.mode === Mode.Play ||
        this.mode === Mode.Huddle ||
        this.mode === Mode.Social
      )
    },
    placeholder() {
      if (!this.isExplain) return "Enter here..."

      return ""
    }
  },
  methods: {
    toggleReveal() {
      this.$store.dispatch("updateGameStatusAny", {
        gameID: this.gameID,
        revealCategoryAnswers: !this.revealCategoryAnswers
      })
    },
    async submit(data, admin) {
      if (admin && !this.isHost) return 0

      if (this.revealCategoryAnswers) return 0

      if (!data) return 0

      const normalized = serialize(data)

      if (this.normalizedWithOptions.includes(normalized)) return 0

      this.answer = normalized

      await this.checkAnswer()
      this.answer = ""
    }
  }
}
</script>

<style lang="scss">
.categories-mission {
  display: flex;
  align-items: center;
  justify-content: center;

  &__instructions {
    min-height: 32px;
  }

  &__title {
    flex: 1;
    min-height: 26px;
  }

  &__toggle-visibility-btn {
    line-height: 1;
  }

  .resizable-text-container--scrollbar {
    overflow: hidden;
  }

  .mission-text-feild {
    position: relative;
    padding: {
      right: 48px;
      left: 48px;
    }
    margin: {
      top: 24px;
      bottom: 24px;

      top: min(max(5px, 1vh), 24px);
      bottom: min(max(5px, 1vh), 24px);
    }
    transform: translateY(10px);

    visibility: hidden;

    opacity: 0;
    transition: all 0.25s ease-out;

    .orientation-portrait & {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .mission-text-feild.show {
    visibility: visible;
    transform: none;
    opacity: 1;
  }
  .text-input {
    pointer-events: none;
    input {
      margin-top: 0 !important;
      caret-color: $color-black !important;
    }
    .v-input__slot {
      margin: 0;
      min-height: auto;
      border-radius: 10px !important;
      border: 2px solid $color-grey-light3 !important;
    }
    .v-text-field__details {
      display: none;
    }
  }
  .text-input.active {
    pointer-events: auto;
  }
  .text-input.wrong ::-webkit-input-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong ::-moz-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong :-moz-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong :-ms-input-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.correct {
    pointer-events: none;
    .v-input__slot {
      border: 2px solid $correct_color !important;
    }
  }
  .text-input-submit {
    padding: 5px;
    font-weight: bold;
    color: $color-grey-light3;
    opacity: 1;
    pointer-events: none;
    cursor: not-allowed;
    transition: color 0.3s;
  }
  .text-input-submit:hover {
    filter: brightness(1.2);
  }
  .text-input-submit.active {
    text-decoration: none;
    color: $primary_accent_color;
    pointer-events: auto;
    cursor: pointer;
  }
  .text-input-submit.correct {
    color: $correct_color;
    pointer-events: none;
    cursor: not-allowed;
  }
  .mission-instructions {
    margin-top: 21px;
    padding: 5px 15px;
  }
}
</style>
