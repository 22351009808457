var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "categories-mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c("ResizableText", {
        staticClass: "categories-mission__title",
        attrs: { message: _vm.mission.instructions },
      }),
      _c(
        "OpacityTransition",
        [
          !_vm.isExplain
            ? _c("OptionsList", {
                attrs: { options: _vm.options, show: "", autoWidth: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (ref) {
                        var option = ref.option
                        return [
                          _c(
                            "FlipVerticalTransition",
                            [
                              option.played
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "ChoiceBtn",
                                                  _vm._g(
                                                    {
                                                      style:
                                                        !_vm.enlargedUI &&
                                                        option.display.length >
                                                          13 &&
                                                        "font-size: 75%",
                                                      attrs: {
                                                        size:
                                                          !_vm.enlargedUI &&
                                                          "sm",
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(option.display) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_vm._v(" " + _vm._s(option.display) + " ")]
                                  )
                                : _c(
                                    "ChoiceBtn",
                                    {
                                      attrs: {
                                        size: !_vm.enlargedUI && "sm",
                                        secondary: true,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.submit(
                                            option.display,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isHost ? option.display : "?"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  606881102
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "SlideDownTransition",
        { attrs: { maxHeight: "160px" } },
        [
          _vm.isHost && _vm.isPlayTime
            ? _c(
                "v-flex",
                {
                  staticStyle: { "margin-bottom": "4px" },
                  attrs: { shrink: "" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "categories-mission__toggle-visibility-btn",
                      on: {
                        click: function ($event) {
                          return _vm.toggleReveal()
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass:
                            "categories-mission__toggle-visibility-btn__icon",
                        },
                        [_vm._v(" visibility ")]
                      ),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.revealCategoryAnswers
                                ? "Hide Answers"
                                : "Reveal Answers"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "SlideDownTransition",
        { attrs: { maxHeight: "160px" } },
        [
          _vm.isInputAvailable
            ? _c(
                "v-flex",
                { staticClass: "w-full", attrs: { shrink: "" } },
                [
                  _c("TextField", {
                    staticClass: "mission-text-feild",
                    attrs: {
                      allowFreeSubmit: "",
                      autoFocusOnSubmit: "",
                      placeholder: _vm.placeholder,
                      status: _vm.status,
                      show: "show",
                      buttonText: "SUBMIT",
                    },
                    on: { onSubmit: _vm.submit },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }